import VueRegistry from '../registry';

import Avatar from './Avatar/Avatar.vue';
import Badge from './Badge/Badge.vue';
import CustomerActivityStream from './Customer/ActivityStream/ActivityStream.vue';
import Example from './Example.vue';
import QuadroContainer from './QuadroContainer/QuadroContainer.vue';

import {
  PhoneNumber,
  SelectMulti,
  SelectSingle,
  SideMenu,
  UserMenuWrapper,
} from '@realpadsoftware/realpad-crm-frontend-lib';

VueRegistry.registerComponent('CustomerActivityStream', CustomerActivityStream);
VueRegistry.registerComponent('Example', Example);
VueRegistry.registerComponent('PhoneNumber', PhoneNumber);
VueRegistry.registerComponent('SelectMulti', SelectMulti);
VueRegistry.registerComponent('SelectSingle', SelectSingle);
VueRegistry.registerComponent('SideMenu', SideMenu);
VueRegistry.registerComponent('UserMenuWrapper', UserMenuWrapper);

// TODO replace with components from realpad-crm-frontend-lib
VueRegistry.registerComponent('Avatar', Avatar);
VueRegistry.registerComponent('Badge', Badge);
VueRegistry.registerComponent('QuadroContainer', QuadroContainer);
