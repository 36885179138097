import Fetch from 'core/fetch';
import I18N from 'core/i18n';
import Log from 'core/log';
import $ from 'jquery';
import { delay } from 'utils/functions';
import { ref } from 'vue';
import { createAlert } from '../../components/alerts';
import Modal from '../../components/modal';
import Offcanvas from '../../components/offcanvas';
import ResponseCommand from '../../components/responseCommand';

const POST_TIMEOUT = 5000;

class Confirmation {
  constructor(props) {
    this.level = props.level ?? 'info';
    this.message = props.message ?? I18N.t('confirm_question');
    this.confirmLabel = props.confirmLabel ?? I18N.t('confirm');
    this.cancelLabel = props.cancelLabel ?? I18N.t('cancel');
  }

  createModal(resolve) {
    /**
     * Dirty hotfix, the value must be higher than
     * --rpvue-zindexDropdown: 1080;
     * coming from the FE lib
    */
    const cssStyle='z-index: 1090'

    const $modal = $(`
      <div class="modal fade" role="dialog" tabindex="-1" style="${cssStyle}">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">${createAlert(this.level, this.message)}</div>
            <div class="modal-footer-container">
              <div class="modal-footer">
                <div class="modal-footer-buttons">
                  <button type="button" class="btn btn-primary-filled confirmation-confirm">
                    <span class="btn-text">${this.confirmLabel}</span>
                  </button>
                  <button type="button" class="btn btn-primary-outline" data-dismiss="modal">
                    <span class="btn-text">${this.cancelLabel}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `);

    $modal.one('shown.bs.modal', () => {
      $modal.on('click.confirmation', '.confirmation-confirm', () => {
        $modal.modal('hide');
        resolve();
      });

      $modal.one('hidden.bs.modal', () => {
        $modal.off('click.confirmation');
        $modal.modal('dispose');
        $modal.remove();
      });
    });

    $modal.appendTo('body');
    $modal.modal();
  }

  handle() {
    return new Promise((resolve) => this.createModal(resolve));
  }
}

class ClickAction {
  constructor(type, url, confirmation, disabledRef) {
    this.type = type;
    this.url = url;
    this.confirmation = confirmation;
    this.disabledRef = disabledRef;
  }

  handle() {
    switch (this.type) {
      case 'request':
        this.confirm().then(() => this.handleRequest());
        break;
      case 'post':
        this.confirm().then(() => this.handlePost());
        break;
      case 'modal':
        this.handleModal();
        break;
      case 'offcanvas':
        this.handleOffcanvas();
        break;
    }
  }

  handleRequest() {
    this.disable();

    Fetch.post(this.url)
      .then(response => new ResponseCommand(response).handle())
      .catch(error => Log.error(error))
      .finally(() => this.enable());
  }

  handlePost() {
    if (Fetch.isValidUrl(this.url)) {
      this.disable();
      delay(POST_TIMEOUT).then(() => this.enable());

      $('<form>')
        .attr('method', 'POST')
        .attr('action', this.url)
        .appendTo(document.body)
        .trigger('submit');
    } else {
      Log.error('URL is not valid', {
        source: 'UseClickAction',
        url: this.url
      });
    }
  }

  handleModal() {
    this.disable();

    new Modal(this.url).open()
      .then(response => new ResponseCommand(response).handle())
      .catch(error => Log.error(error))
      .finally(() => this.enable());
  }

  handleOffcanvas() {
    this.disable();

    new Offcanvas(this.url).open()
      .catch(error => Log.error(error))
      .finally(() => this.enable());
  }

  confirm() {
    this.disable();

    if (this.confirmation) {
      return new Confirmation(this.confirmation).handle();
    }

    return Promise.resolve();
  }

  enable() {
    this.disabledRef.value = false;
  }

  disable() {
    this.disabledRef.value = true;
  }
}

export function useClickActions() {
  const disabled = ref(false);

  function clickAction(type, url, confirmation) {
    new ClickAction(type, url, confirmation, disabled).handle();
  }

  return { clickAction, disabled };
}
